<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
      <lz-card title="权限信息">
        <lz-tree
          show-check-box
          :props="{
            label: 'title'
          }"
          ref="lzTree"
          check-strictly
          :defaultCheckedKeys="tree.defaultCheckedKeys"
          :list="tree.list">
        </lz-tree>
      </lz-card>
    </lz-dialog>
  </div>
</template>
<script>
import { del, save } from '@/api/user-group';

export default {
  data() {
    return {
      search: [
        { name: '角色名称', type: 'text', fieldName: 'keyword', value: '' },
      ],
      config: {
        url: '/auth-group/list',
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '名称', prop: 'name' },
        {
          name: '是否显示',
          func: (ops) => (ops.status ? '否' : '是'),
        },
        { name: '创建时间', prop: 'create_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [
        { fieldName: 'name', type: 'text', name: '角色名称', value: '', required: true },
        { fieldName: 'sort', type: 'number', name: '排序', value: '', required: true },
        {
          fieldName: 'status',
          type: 'select',
          name: '状态',
          required: true,
          value: '',
          options: [
            { label: '启用', value: 0 },
            { label: '禁用', value: 1 },
          ],
        },
        { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
        { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      tree: {
        list: [],
        defaultCheckedKeys: [],
      },
      title: '',
    };
  },
  mounted() {
    this.$baseHttp.get('/auth-rule/list', {
      params: { type: 1 },
      noLoading: true,
    }).then((response) => {
      this.tree.list = response.data;
    });
  },
  methods: {
    handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await del(element.id);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          params.rule_ids = this.$refs.lzTree.getCheckedKeys();
          params.rule_ids.push(...this.$refs.lzTree.getHalfCheckedKeys());
          const response = await save(params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {},
        });
      });
      this.tree.defaultCheckedKeys = response ? response.rule_ids : [];
    },
  },
};
</script>
<style lang="scss" scoped></style>
