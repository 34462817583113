import request from '@/utils/request';

// 删除
export function del(id) {
  return request({
    url: `/auth-group/delete?ids=${id}`,
    method: 'delete',
  });
}

// 修改/新增
export function save(data) {
  return request({
    data,
    url: '/auth-group/save',
    method: 'post',
  });
}
